import React from "react";
import Hero from "../../components/reusable/Hero/Hero";
import Student from "../../components/reusable/Student/Student";
import InfoCards from "../../components/reusable/InfoCards/InfoCards";
import Questions from "../../components/reusable/Questions/Questions";
import Process from "../../components/reusable/Process/Process";
import PriceMentoring from "../../components/reusable/Price/PriceMentoring";
import NewsletterSection from "../../components/reusable/Newsletter/NewsletterSection";

import {
  SponsorImgContainer,
  MentoringSponsorship,
  SponsorImg,
  MewsLogo,
  LenovoLogo,
} from "../../components/Sponsor/Sponsor.elements.js";

import {
  mentStudentArr,
  heroObjTwo,
  infoObjOne,
  mentoringCardArr,
  titleObjOne,
  mentPriceArr,
  mentQuestionsArr,
  processArrMent,
  mentPrice,
  mentoringTimeline,
} from "./Data";
import { Timeline } from "../../components/Program/Timeline";

function Mentoring({ showNewsletter }) {
  return (
    <div>
      <Hero {...heroObjTwo} />
      <MentoringSponsorship>
        <SponsorImgContainer>
          <a
            href="https://search.jobs.barclays/barclays-developers-prague"
            target="blank"
          >
            <SponsorImg src="/images/sponsors/barclays-01.png" />
          </a>
          {/*<a href="https://www.lenovo.com/cz/cs/pc/" target="blank">
            <LenovoLogo src="/images/sponsors/lenovo.svg" />
  </a>*/}
          <a href="https://www.mews.com/en" target="blank">
            <MewsLogo src="/images/sponsors/MEWS.png" />
          </a>
        </SponsorImgContainer>
      </MentoringSponsorship>
      {/*<Info {...infoObjOne} />
      {/*<Timeline data={mentoringTimeline} />*/}
      <InfoCards data={mentoringCardArr} {...titleObjOne} />
      <Student data={mentStudentArr} {...titleObjOne} />
      <Process data={processArrMent} />
      <PriceMentoring
        showLogInButton={mentPrice.showLogInButton}
        data={mentPriceArr}
        mentPrice={mentPrice}
        titleObjOne={titleObjOne}
        form={mentPrice.form}
      />
      {/*showNewsletter && <NewsletterSection />*/}
      <Questions data={mentQuestionsArr} {...infoObjOne} />
    </div>
  );
}

export default Mentoring;
