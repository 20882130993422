import { LinkA } from "../../components/reusable/styled";

/* switch between Newsletter and LogInButton on Hero Mentoring page*/
export const heroObjTwo = {
  title: "Zúčastni se jedinečného Mentoringu s ReactGirls!",
  subtitle:
    "Přihlašování na mentoring 2024 spustíme v srpnu, začátek mentoringu plánujeme na říjen.",
  buttonText: "ODEBÍRAT NEWSLETTER",
  img: "/images/gallery/mentoring.jpg",
  form: "https://forms.gle/NNKpvj3hvwFDvrJu8",
  showNewsletter: false,
  showLogInButton: false,
  isDisabled: false,
};

/* switch between Newsletter and LogInButton on Price Container of Mentoring page*/

export const mentPrice = {
  price: "",
  form: "https://forms.gle/NNKpvj3hvwFDvrJu8",
  showNewsletter: false,
  showLogInButton: false,
  showBorderBottom: true,
};

export const infoObjOne = {
  text: "Přihlas se do Mentoringu ReactGirls a získej kouče, který tě bude provázet na ceste k rozvoji tvých technických dovedností.",
  content: "mentoring",
};

export const mentoringCardArr = [
  {
    icon: "/images/icons/week.svg",
    title: "Týdenní setkávání",
    text: "Pravidelná týdenní setkávání s mentorem po dobu 90 minut, který tě během 3 měsíců provede tvorbou projektu a code review.",
  },
  {
    icon: "/images/icons/coach.svg",
    title: "Koučové k dispozici",
    text: "K dispozici na horké lince budeš mít i další kouče-specialisty z rozdílných oblastí, kteří ti poradí.",
  },
  {
    icon: "/images/icons/flexible.svg",
    title: "Flexibilita",
    text: "Můžeš si vybrat formu setkávání, ať už online, nebo offline, a to dle tvých časových možností.",
  },
  {
    icon: "/images/icons/feedback.svg",
    title: "Feedback",
    text: "Na závěr mentoringu získáš po odprezentování projektu feedback od dalších odborníků a rady jak se posouvat i po jeho skončení.",
  },
  {
    icon: "/images/icons/interview.svg",
    title: "Mock interview",
    text: "Po absolvování mentoringu budeš mít jedinečnou možnost vyzkoušet si mock interview se zkušeným HR.",
  },
  {
    icon: "/images/icons/certificate.svg",
    title: "Certifikát",
    text: "Obdržíš certifikát o absolvování mentoringu a získáš cenné kontakty v IT světě.",
  },
];

export const titleObjOne = {
  mainStudentTitle: "Pro koho je Mentoring určen?",
  mainCardsTitle: "Co Mentoring nabízí?",
  mainPriceTitle: "Co vše je v ceně Mentoringu?",
};

export const mentStudentArr = [
  {
    icon: "/images/icons/website.svg",
    heading: "Zájemkyním",
    text: "Zájemkyním o programování, které se chtějí naučit tvorbu webových aplikací v populární JavaScriptové knihovně React.",
  },
  {
    icon: "/images/icons/victory.svg",
    heading: "Začátečnicím",
    text: "Začatečnicím, které již mají zkušenosti s programováním, znají základy HTML a CSS, případně JS a chtěly by je posunout na další úroveň s Reactem.",
  },
  {
    icon: "/images/icons/woman3.svg",
    heading: "Ženám",
    text: "Ženám, které se chtějí naučit frontendové programování a být mentorované osobnostmi z IT.",
  },
];

export const mentPriceArr = [
  {
    text: "mentor po dobu 3 měsíců",
  },
  {
    text: "pravidelná týdenní setkávání s mentorem",
  },
  {
    text: "plánování agendy",
  },
  {
    text: "code review",
  },
  {
    text: "online konzultace s dalšími kouči",
  },
  {
    text: "prezentace vytvořeného projektu",
  },
  {
    text: "mock interview s HR",
  },
];

export const mentQuestionsArr = [
  {
    question: "Pro koho je Mentoring určen?",
    answer:
      "Začátečnicím, které již mají zkušenosti s programováním, znají základy HTML a CSS, případně JS, a chtěly by je posunout na další úroveň s Reactem.",
  },
  {
    question: "Jak se můžu na Mentoring přihlásit?",
    answer: (
      <>
        Stačí kliknout{" "}
        <LinkA href="https://forms.gle/NNKpvj3hvwFDvrJu8" target="blank">
          ZDE{" "}
        </LinkA>
        a vyplnit registrační formulář.
      </>
    ),
  },
  {
    question: "Co si z Mentoringu odnesu?",
    answer:
      "Odneseš si znalosti HTML, CSS, JavaScriptu, Reactu. Získáš reálnou zkušenost s Reactem a motivaci do dalšího seberozvoje. Budeš mít možnost si vyzkoušet interview se zkušeným HR a dostaneš certifikát o absolvování Mentoringu a ReactGirls merch.",
  },
  {
    question: "Stále si nejsi jistá?",
    answer: (
      <>
        {" "}
        Pokud se chceš ujistit, že je to to pravé, přečti si článek o{" "}
        <LinkA
          href="https://reactgirls.medium.com/co-p%C5%99inesl-jarn%C3%AD-mentoring-reactgirls-da65a96000a3"
          target="blank"
        >
          loňském běhu
        </LinkA>{" "}
        nebo{" "}
        <LinkA
          href="https://reactgirls.medium.com/rozhovor-s-absolventkou-mentoringu-pro%C5%BE%C3%ADv%C3%A1m-sv%C5%AFj-spln%C4%9Bn%C3%BD-sen-7c2bf56314b8"
          target="blank"
        >
          rozhovor s naší absolventkou
        </LinkA>
        . V případě dalších otázek nás neváhej{" "}
        <LinkA href="/kontakt">kontaktovat</LinkA>.
      </>
    ),
  },
];

export const processArrMent = [
  {
    number: 1,
    heading: "REGISTRACE",
    text: "V případě, že je momentálně otevřené přihlašování na Mentoring, klikni níže na tlačítko REGISTRACE. Pokud aktuálně není registrace otevřena, můžeš odebírat náš Newsletter, kde se o startu dalšího běhu Mentoringu v předstihu dozvíš.",
    reversed: false,
    last: false,
    first: true,
  },
  {
    number: 2,
    heading: "VYPLŇ FORMULÁŘ",
    text: "Po kliknutí na tlačítko REGISTRACE se objeví formulář. Při vyplňování nezapomeň zejména na popsání své motivace a přiložení odkazu na GitHub či jiné úložiště tvých kódů.",
    reversed: true,
    last: false,
    first: false,
  },
  {
    number: 3,
    heading: "KONTAKTUJEME TĚ",
    text: "Před spuštěním běhu tě budeme kontaktovat a sdělíme ti, zda jsme tě do Mentoringu zařadili. Pošleme ti taky všechny potřebné informace o programu a platbě.",
    reversed: false,
    last: true,
    first: false,
  },
];

export const mentoringTimeline = [
  {
    date: "27.9.2023",
    topic: "Zahájení mentoringu",
    place: "ČSOB Kampus",
    address:
      "https://www.google.com/maps/place/%C4%8CSOB+Stavebn%C3%AD+spo%C5%99itelna,+a.s./@50.058373,14.3862547,18z/data=!4m6!3m5!1s0x470b931389127f0f:0xe5d8618840191c72!8m2!3d50.0584987!4d14.3873614!16s%2Fg%2F1tg33ydp?entry=ttu",
    border: true,
  },
  {
    date: "4.10.2023",
    topic:
      "Working session - dobrovolné setkání a společná práce na projektu s ostatními mentees",
    place: "Node5",
    address:
      "https://www.google.com/maps/place/Node5/@50.066358,14.4001695,17z/data=!3m1!4b1!4m6!3m5!1s0x470b9450c0dcebfb:0x2fad6c1cd982e330!8m2!3d50.066358!4d14.4027444!16s%2Fg%2F119v2s7m6?entry=ttu",
    border: true,
  },
  {
    date: "15.11.2023",
    topic: "První prezentace projektu",
    place: "TBA",
    address: "",
    border: true,
  },
  {
    date: "29.11.2023",
    topic:
      "Working session - dobrovolné setkání a společná práce na projektu s ostatními mentees",
    place: "TBA",
    address: "",
    border: true,
  },
  {
    date: "20.12.2023",
    topic: "Konec mentoringu",
    border: true,
  },
  {
    date: "11.1.2024",
    topic: "Závěrečné prezentace, gala večer",
    place: "TBA",
    address: "",
    border: false,
  },
];
